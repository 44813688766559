<template>
  <div>
    <a
      class="has-text-weight-medium has-text-right is-block"
      @click="openModal"
      >{{ $t("af:profile.modal.email.button") }}</a
    >

    <b-modal v-model="isModalOpen" :can-cancel="false" :width="650">
      <div class="af-modal-card">
        <span v-if="isEmailExists && !newCodeSent">
          <p class="has-text-weight-bold is-size-4">
            {{ $t("af:profile.modal.email.title") }}
          </p>

          <p v-if="isEmailConfirmed">
            {{ $t("af:profile.modal.email.edit.subtitle") }}
          </p>
          <p v-else>
            <span
              v-html="
                $t('af:profile.modal.email.edit_not_confirmed.subtitle', {
                  email: email,
                })
              "
            ></span>
          </p>
        </span>

        <span v-if="!isEmailExists && !newCodeSent">
          <p class="has-text-weight-bold is-size-4 mb-6">
            {{ $t("af:profile.modal.email.new.subtitle") }}
          </p>
        </span>

        <span v-if="!newCodeSent">
          <form @submit.prevent="save" class="mt-6" autocomplete="new-password">
            <validation-observer ref="observer">
              <BInputWithValidation
                rules="required|email"
                :label="$t('af:profile.modal.email.input.email.label')"
                label-position="on-border"
                v-model="newEmail"
              />

              <ValidationProvider
                rules="required"
                v-slot="{ errors, validated, valid }"
              >
                <b-field
                  :label="$t('af:profile.modal.email.password.email.label')"
                  label-position="on-border"
                  name="application-code"
                  class="mb-5"
                  :type="{
                    'is-danger': errors[0],
                    'is-success': validated && valid,
                  }"
                  :message="errors"
                >
                  <b-input
                    type="password"
                    autocomplete="new-password"
                    password-reveal
                    v-model="password"
                  ></b-input>
                </b-field>
              </ValidationProvider>

              <p class="has-text-weight-bold has-text-centered mt-5">
                <b-button
                  type="is-blue"
                  :value="$t('af:profile.modal.email.button.save')"
                  tag="input"
                  @click="save"
                ></b-button>
                <b-button
                  v-if="!isEmailConfirmed && isEmailExists"
                  :value="$t('af:profile.modal.email.button.new_code')"
                  tag="input"
                  class="ml-4"
                  @click="sendNewCode"
                ></b-button>
              </p>
            </validation-observer>
          </form>
        </span>

        <span v-if="newCodeSent">
          <p class="has-text-weight-bold is-size-4 mb-4">
            {{ $t("af:profile.modal.email.email_sent.title") }}
          </p>

          <p>
            <span
              v-html="
                $t('af:profile.modal.email.email_sent.subtitle', {
                  email: email,
                })
              "
            ></span>
          </p>

          <img
            class="mx-auto is-block"
            :src="require('@/assets/images/mail-sent.svg')"
            alt="mail-sent"
          />
        </span>

        <div class="close-btn cursor-pointer" @click="cancelModal">
          <b-icon icon-pack="fas" icon="times" type="is-grey-icon" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import { generateNonce } from "../../utils/util";

export default {
  name: "UserEditEmailModal",
  components: {
    BInputWithValidation: () => import("@/components/BInputWithValidation.vue"),
  },
  props: {
    currentEmail: {
      type: String,
      required: true,
    },
    isEmailConfirmed: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isEmailExists() {
      return !!this.currentEmail;
    },
  },
  data() {
    return {
      isModalOpen: false,
      email: "",
      newEmail: "",
      password: "",
      newCodeSent: false,
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
      this.email = this.currentEmail;
      this.newCodeSent = false;
    },
    cancelModal() {
      this.isModalOpen = false;
      this.$emit("save");
    },
    async sendNewCode() {
      await this.$store.dispatch(
        "profile/sendConfirmationEmail",
        generateNonce()
      );
      this.newCodeSent = true;

      await this.$store.dispatch("profile/updateCustomerEmailConfirm", {
        offerId: null,
      });
    },
    async save() {
      const valid = await this.$refs.observer.validate();

      if (!valid) return;

      try {
        await this.$store.dispatch("profile/emailChange", {
          nonce: generateNonce(),
          email: this.newEmail,
          password: this.password,
        });
        this.newCodeSent = true;
        this.email = this.newEmail;
      } catch (ex) {
        if (ex.response.status === 409) {
          this.$buefy.toast.open({
            message: i18n.t("af:profile.modal.email.message.email_exist"),
            type: "is-danger",
          });
        }
        if (ex.response.status === 406) {
          this.$buefy.toast.open({
            message: i18n.t("af:profile.modal.email.message.password_error"),
            type: "is-danger",
          });
        }
      }
    },
  },
};
</script>

<style scoped></style>
