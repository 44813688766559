var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a',{staticClass:"has-text-weight-medium has-text-right is-block",on:{"click":_vm.openModal}},[_vm._v(_vm._s(_vm.$t("af:profile.modal.email.button")))]),_c('b-modal',{attrs:{"can-cancel":false,"width":650},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c('div',{staticClass:"af-modal-card"},[(_vm.isEmailExists && !_vm.newCodeSent)?_c('span',[_c('p',{staticClass:"has-text-weight-bold is-size-4"},[_vm._v(" "+_vm._s(_vm.$t("af:profile.modal.email.title"))+" ")]),(_vm.isEmailConfirmed)?_c('p',[_vm._v(" "+_vm._s(_vm.$t("af:profile.modal.email.edit.subtitle"))+" ")]):_c('p',[_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.$t('af:profile.modal.email.edit_not_confirmed.subtitle', {
                email: _vm.email,
              })
            )}})])]):_vm._e(),(!_vm.isEmailExists && !_vm.newCodeSent)?_c('span',[_c('p',{staticClass:"has-text-weight-bold is-size-4 mb-6"},[_vm._v(" "+_vm._s(_vm.$t("af:profile.modal.email.new.subtitle"))+" ")])]):_vm._e(),(!_vm.newCodeSent)?_c('span',[_c('form',{staticClass:"mt-6",attrs:{"autocomplete":"new-password"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('validation-observer',{ref:"observer"},[_c('BInputWithValidation',{attrs:{"rules":"required|email","label":_vm.$t('af:profile.modal.email.input.email.label'),"label-position":"on-border"},model:{value:(_vm.newEmail),callback:function ($$v) {_vm.newEmail=$$v},expression:"newEmail"}}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var validated = ref.validated;
            var valid = ref.valid;
return [_c('b-field',{staticClass:"mb-5",attrs:{"label":_vm.$t('af:profile.modal.email.password.email.label'),"label-position":"on-border","name":"application-code","type":{
                  'is-danger': errors[0],
                  'is-success': validated && valid,
                },"message":errors}},[_c('b-input',{attrs:{"type":"password","autocomplete":"new-password","password-reveal":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}],null,false,223707983)}),_c('p',{staticClass:"has-text-weight-bold has-text-centered mt-5"},[_c('b-button',{attrs:{"type":"is-blue","value":_vm.$t('af:profile.modal.email.button.save'),"tag":"input"},on:{"click":_vm.save}}),(!_vm.isEmailConfirmed && _vm.isEmailExists)?_c('b-button',{staticClass:"ml-4",attrs:{"value":_vm.$t('af:profile.modal.email.button.new_code'),"tag":"input"},on:{"click":_vm.sendNewCode}}):_vm._e()],1)],1)],1)]):_vm._e(),(_vm.newCodeSent)?_c('span',[_c('p',{staticClass:"has-text-weight-bold is-size-4 mb-4"},[_vm._v(" "+_vm._s(_vm.$t("af:profile.modal.email.email_sent.title"))+" ")]),_c('p',[_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.$t('af:profile.modal.email.email_sent.subtitle', {
                email: _vm.email,
              })
            )}})]),_c('img',{staticClass:"mx-auto is-block",attrs:{"src":require('@/assets/images/mail-sent.svg'),"alt":"mail-sent"}})]):_vm._e(),_c('div',{staticClass:"close-btn cursor-pointer",on:{"click":_vm.cancelModal}},[_c('b-icon',{attrs:{"icon-pack":"fas","icon":"times","type":"is-grey-icon"}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }